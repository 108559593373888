import React, { useEffect, useRef, useState } from "react";
import { OverlayProvider, usePreventScroll } from "react-aria";
import { json, useLocation, useNavigate, useParams } from "react-router-dom";
import "./CourseDetails.css";
import { HiUserGroup } from "react-icons/hi";
import star from "../../../../images/course_details_icon/GroupStar.png";
/* import CourseDetailsInformation from "./CourseDetailsInformation/CourseDetailsInformation"; */
import CourseShortInformation from "./CourseShortInformation/CourseShortInformation";
import Icon from "../../../../images/people.png";
import videoIconCourseDetails from "../../../../images/course-video.png";
import CourseDescription from "./CourseDetailsInformation/CourseDescription/CourseDescription";
import ClassSchedule from "./CourseDetailsInformation/ClassSchedule/ClassSchedule";
import CourseReview from "./CourseDetailsInformation/CourseReview/CourseReview";
import NoVideo from "../../../../images/default_video_banner.jpg";
import Faq from "./CourseDetailsInformation/Faq/Faq";
import { decode as base64_decode } from "base-64";
import { RadioGroup } from "@headlessui/react";
import PageTitle from "../PageTitle/PageTitle";
import Loader from "../Loader/Loader";
import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { createContext } from "react";
import { englishToBengaliNumberFormatWithZero } from "../../../../utilities/utilities";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import ReactGA from "react-ga4";
import mixpanel from "mixpanel-browser";
import TagManager from "react-gtm-module";
import utils from "../../../../utils/index";
import { Link } from "react-router-dom";
import SlideOver from "./components/SlideOver";
import PaymentSegment from "./components/PaymentSegment";
import CourseTeacher from "./CourseTeacher/CourseTeacher";
import Rating from "../Rating/Rating";
import ShowRating from "../Rating/ShowRating";
import { ArrowDownIcon } from "@heroicons/react/16/solid";
import OneToOneTimeSchedule from "./components/OneToOneTimeSchedule/OneToOneTimeSchedule";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import SingleStarRating from "./CourseDetailsInformation/CourseReview/Review/OneStarReview";
import { Helmet } from "react-helmet-async";

export const CourseDetailContext = createContext({});

const CourseDetails = () => {
  const [courseDetail, setCourseDetail] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const handleOnClose = () => setIsOpen(false);
  const [payment, setPayment] = useState([]);
  const [batchName, setBatchName] = useState("");
  const [selectedBatch, setSelectedBatch] = useState();
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [selectedPaymentSegmentId, setSelectedPaymentSegmentId] =
    useState(null);
  const [showAllTeacher, setShowAllTeacher] = useState(false);
  const [showAllSyllabuses, setShowAllSyllabuses] = useState(false);
  const [isSelectOneToOne, setIsSelectOneToOne] = useState(false);
  const { t } = useTranslation();
  const [language, setLanguage] = useState("bn");
  const [activeTab, setActiveTab] = useState("description");

  const [fix, setFix] = useState(false);

  //Spinner Button

  const descriptionRef = useRef(null);
  const curriculumRef = useRef(null);
  const teachersRef = useRef(null);
  const faqRef = useRef(null);
  const reviewRef = useRef(null);
  const timeScheduleRef = useRef(null);

  const navigate = useNavigate();

  usePreventScroll({ isDisabled: !isOpen });

  //   Handle auto higilight navbar function
  const handleScroll = () => {
    const sections = [
      { ref: descriptionRef, name: "description" },
      { ref: curriculumRef, name: "curriculum" },
      { ref: reviewRef, name: "my_review" },
      { ref: teachersRef, name: "teachers" },
      { ref: faqRef, name: "faq" },
    ];

    let foundSection = "";

    sections.forEach((section) => {
      const sectionTop = section.ref.current?.offsetTop - 200;
      const sectionBottom = sectionTop + section.ref.current?.offsetHeight;

      // Check if current scroll position is within the section's bounds
      if (window.scrollY >= sectionTop && window.scrollY < sectionBottom) {
        foundSection = section.name;
      }
    });

    if (foundSection && foundSection !== activeTab) {
      setActiveTab(foundSection);
    }
  };

  // Scroll
  useEffect(() => {
    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);
    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [activeTab]); // Depend on activeTab to trigger re-render when it changes

  // Function to handle scroll
  const fixedNav = () => {
    if (window.scrollY >= 680) {
      setFix(true);
    } else {
      setFix(false);
    }
  };

  //   Course navbar
  useEffect(() => {
    window.addEventListener("scroll", fixedNav);
    return () => {
      window.removeEventListener("scroll", fixedNav);
    };
  }, []);

  //Mouse Events
  const handleTimeScheduleRef = () => {
    if (timeScheduleRef.current) {
      const topPosition =
        timeScheduleRef.current.getBoundingClientRect().top + window.scrollY;
      const marginTop = window.innerHeight * 0.4;
      window.scrollTo({
        top: topPosition - marginTop,
        behavior: "smooth",
      });
    }
  };

  // Tab click handler
  const handleTabClick = (section) => {
    setActiveTab(section);
    const sectionRefs = {
      description: descriptionRef,
      curriculum: curriculumRef,
      teachers: teachersRef,
      faq: faqRef,
      my_review: reviewRef,
    };

    const sectionRef = sectionRefs[section];
    if (sectionRef.current) {
      // Adjust scroll position to account for the 200px offset
      const topPosition =
        sectionRef.current.getBoundingClientRect().top + window.scrollY - 185;
      window.scrollTo({
        top: topPosition,
        behavior: "smooth",
      });
    }
  };

  const location = useLocation();
  const search = useLocation().search;
  const course_id =
    location?.state?.course_id || new URLSearchParams(search).get("course_id");
  const affiliate_id = new URLSearchParams(search).get("aff");
  let decode = base64_decode(`${course_id}`);

  const { courseName } = useParams();

  let url;

  if (!courseName) {
    url = `${process.env.REACT_APP_BASE_URL}api/course-detail?course_id=${decode}`;
  } else {
    url = `${process.env.REACT_APP_BASE_URL}api/course-detail-by-slug?course_slug=${courseName}`;
  }

  // On page load set Course details data to pixels and set the course id
  useEffect(() => {
    async function fetchMyAPI() {
      let response = await fetch(url);
      response = await response.json();
      TagManager.dataLayer({
        dataLayer: {
          event: "pageView",
          pagePath: `${response.data?.course_detail?.course_name}`,
          PageTitle: `${response.data?.course_detail?.course_name}`,
          price: response.data?.course_detail?.course_current_price,
          currency: "BDT",
        },
      });

      const pixelData = {
        page: `${response.data?.course_detail?.course_name} course`,
      };
      utils.addFacebookEvent("Content View", pixelData);
      //-------Set Course Details on load
      setCourseDetail(response.data);
      setIsLoading(false);
    }

    fetchMyAPI();
  }, [courseName]);

  // On page load set fetch course based on ID and set the Course details
  const courseId = courseDetail?.course_detail?.id;

  useEffect(() => {
    async function fetchMyAPI() {
      let response = await fetch(
        // `${process.env.REACT_APP_BASE_URL_STUDENT}course-payment-information?course_id=${courseId}`,
        `https://new.mojaru.com/api/v2/student/course-payment-information?course_id=${courseId}`,
        {
          method: "GET",
          headers: {
            "content-type": "application/json",
            accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
            SecretKey: `${process.env.REACT_APP_API_URL_SECRET_KEY}`,
          },
        }
      );
      response = await response.json();
      //---Set the Course details data when the page is loading
      console.log("Course Payment Data==>", response.data.admission_price);
      localStorage.setItem(
        "admission_fee",
        JSON.stringify(response?.data?.admission_price)
      );
      setPayment(response.data);

      if (response.data.course_payment_segments.length > 0) {
        setSelectedPayment(response.data.course_payment_segments[0]);
      }
    }

    fetchMyAPI();
  }, [courseId]);

  const imagePath = courseDetail?.course_image_path;
  const imageLink = courseDetail?.course_detail?.course_picture;
  const courseBatch = payment?.course_batches || [];

  const coursePayment = payment?.course_payment_segments || [];

  useEffect(() => {
    if (courseDetail?.course_detail?.course_name) {
      ReactGA.event({
        category: "course view",
        action: "course view action",
        label: `Viewed ${courseDetail?.course_detail?.course_name} course`,
        value: localStorage.getItem("mobile_or_email")
          ? Number(localStorage.getItem("mobile_or_email"))
          : undefined,
      });
    }
  }, [courseDetail]);

  // Language switcher
  useEffect(() => {
    const useLanguage = sessionStorage.getItem("useLanguage");
    if (!useLanguage) {
      sessionStorage.setItem("useLanguage", "bn");
      i18next.changeLanguage("bn");
      setLanguage("bn");
    } else {
      i18next.changeLanguage(useLanguage);
      setLanguage(useLanguage);
    }
  }, []);

  // Toggle Language
  function toggleLanguage() {
    const getLanguage = sessionStorage.getItem("useLanguage");

    if (getLanguage === "bn") {
      sessionStorage.setItem("useLanguage", "en");
      i18next.changeLanguage("en");
      setLanguage("en");
    } else {
      sessionStorage.setItem("useLanguage", "bn");
      i18next.changeLanguage("bn");
      setLanguage("bn");
    }
  }
  //Translation End

  useEffect(() => {
    if (courseBatch.length > 0) {
      setBatchName(courseBatch[0].batch_name);
      setSelectedBatch(courseBatch[0]);
    }
  }, [courseBatch]);

  mixpanel.track("Course Open", {
    number: localStorage.getItem("mobile_or_email")
      ? Number(localStorage.getItem("mobile_or_email"))
      : "Not Registered User",
  });

  const handleBatch = (event) => {
    const batchName = event.target.value;
    const selectedBatch = courseBatch.find(
      (batch) => batch.batch_name === batchName
    );
    setBatchName(batchName);
    setSelectedBatch(selectedBatch);
  };

  //Handle Segment Change
  const handleSelectedPaymentChange = (value) => {
    setSelectedPayment(value);
    setSelectedPaymentSegmentId(value.id);
  };

  const courseNames = courseDetail?.course_detail?.course_name;
  const courseClasses = courseDetail?.course_detail?.course_total_live_class;
  const basePath = imagePath;
  const courseImagePath = imageLink;

  const courseInfo = {
    courseNames,
    courseClasses,
    basePath,
    courseImagePath,
  };
  localStorage.setItem("course_info", JSON.stringify(courseInfo));
  localStorage.setItem("payment", JSON.stringify(payment));

  const handleSetItem = () => {
    localStorage.setItem("selected_batch", JSON.stringify(selectedBatch));
    localStorage.setItem("selected_payment", JSON.stringify(selectedPayment));
    localStorage.setItem(
      "payment_segment_id",
      JSON.stringify(selectedPaymentSegmentId)
    );
  };
  const handleClick = () => {
    handleOnClose();
    handleSetItem();
  };
  if (isLoading) {
    return <Loader />;
  }

  const teacherImagePath = courseDetail?.teacher_profile_picture_path;
  const teachers = courseDetail?.course_teachers;

  const handleToggle = () => {
    setShowAllTeacher((prevShowAll) => !prevShowAll);
  };

  const displayedTeachers = showAllTeacher ? teachers : teachers.slice(0, 6);

  const handleToggleSyllabuses = () => {
    setShowAllSyllabuses((prevShowAll) => !prevShowAll);
  };

  const displayedSyllabuses = showAllSyllabuses
    ? courseDetail?.syllabuses
    : courseDetail?.syllabuses?.slice(0, 4);

  console.log("Course Details :", courseDetail);
  return (
    <CourseDetailContext.Provider
      value={{ courseDetail, batchName, selectedPayment }}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {courseDetail?.course_detail?.course_name || "Meta title"}
        </title>
        <meta
          property="og:title"
          content={
            courseDetail?.course_detail?.course_meta_title ||
            "Mojaru Educations"
          }
        />
        <meta
          name="description"
          content={
            courseDetail?.course_detail?.course_meta_descriptions ||
            "Mojaru Education Technologies Limited"
          }
        />
        <meta
          property="og:description"
          content={
            courseDetail?.course_detail?.course_meta_descriptions ||
            "Mojaru Education Technologies Limited"
          }
        />
      </Helmet>
      {/* <PageTitle title="Course Details"></PageTitle> */}
      <ScrollToTop></ScrollToTop>
      <>
        {/* <Header></Header> */}
        <OverlayProvider>
          <div className="borderTop tw-bg-gray-50 tw-pb-20">
            <div className="tw-max-w-[1440px] tw-mx-auto">
              <div className="row mx-0 gy-0 course-detail-top">
                <div className="col-lg-8">
                  <div className="tw-flex tw-items-center">
                    <div className="tw-mr-7 tw-flex tw-items-center tw-bg-red-100 tw-px-3 tw-py-1 tw-rounded-3xl">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M5.05038 3.63591C5.4409 4.02644 5.4409 4.6596 5.05038 5.05012C2.31671 7.78379 2.3167 12.2159 5.05037 14.9496C5.4409 15.3401 5.4409 15.9733 5.05037 16.3638C4.65985 16.7544 4.02668 16.7544 3.63616 16.3638C0.121442 12.8491 0.121443 7.15063 3.63616 3.63591C4.02669 3.24539 4.65985 3.24539 5.05038 3.63591ZM14.9499 3.63614C15.3404 3.24562 15.9736 3.24562 16.3641 3.63614C19.8788 7.15086 19.8788 12.8493 16.3641 16.3641C15.9736 16.7546 15.3404 16.7546 14.9499 16.3641C14.5594 15.9735 14.5594 15.3404 14.9499 14.9499C17.6836 12.2162 17.6836 7.78403 14.9499 5.05036C14.5594 4.65983 14.5594 4.02667 14.9499 3.63614ZM7.87881 6.46434C8.26933 6.85486 8.26933 7.48803 7.87881 7.87855C6.70724 9.05012 6.70723 10.9496 7.87881 12.1212C8.26933 12.5117 8.26933 13.1449 7.87881 13.5354C7.48828 13.9259 6.85512 13.9259 6.46459 13.5354C4.51197 11.5828 4.51197 8.41696 6.46459 6.46434C6.85512 6.07381 7.48828 6.07381 7.87881 6.46434ZM12.1215 6.46457C12.512 6.07405 13.1451 6.07405 13.5357 6.46457C15.4883 8.41719 15.4883 11.583 13.5357 13.5356C13.1451 13.9262 12.512 13.9262 12.1215 13.5356C11.7309 13.1451 11.7309 12.5119 12.1215 12.1214C13.293 10.9499 13.293 9.05036 12.1215 7.87878C11.7309 7.48826 11.7309 6.85509 12.1215 6.46457ZM10.0001 9.0001C10.5524 9.0001 11.0001 9.44782 11.0001 10.0001V10.0101C11.0001 10.5624 10.5524 11.0101 10.0001 11.0101C9.44784 11.0101 9.00012 10.5624 9.00012 10.0101V10.0001C9.00012 9.44782 9.44784 9.0001 10.0001 9.0001Z"
                          fill="#EF4444"
                        />
                      </svg>
                      <p className="tw-text-red-800 tw-ml-2">
                        {courseDetail?.course_detail?.course_mode_id == 1 ? (
                          <span>{t("Live")}</span>
                        ) : (
                          <span>{t("Recorded")}</span>
                        )}
                      </p>
                    </div>

                    {courseDetail?.total_students >= 50 && (
                      <div className="d-flex align-items-center pe-4">
                        <HiUserGroup className="tw-w-5 tw-h-5 tw-text-gray-500" />
                        <p className="font-16 mb-0 pt-1 ps-1 course-student balooda-regular">
                          <span className="font-16 balooda-regular tw-hidden md:tw-inline-block">
                            {t("Enrolled")} :
                          </span>
                          {englishToBengaliNumberFormatWithZero(
                            courseDetail?.total_students
                          )}
                        </p>
                      </div>
                    )}

                    <div className="d-flex align-items-center">
                      <SingleStarRating
                        rating={courseDetail?.course_average_rating}
                        // color={{
                        //   filled: "#FB923C",
                        //   halfFilled: "#FFDD99",
                        //   unfilled: "#DCDCDC",
                        // }}
                      />

                      <p className="tw-text-gray-700 tw-text-lg tw-ml-3">
                        ({courseDetail?.course_average_rating})
                      </p>
                    </div>
                  </div>
                  <p className="tw-mt-3 md:tw-text-4xl md:tw-font-bold">
                    {courseDetail?.course_detail?.course_name}
                  </p>

                  <p className="tw-text-gray-500 tw-mt-3 tw-pr-10">
                    {courseDetail?.course_detail?.course_short_details}
                  </p>
                  {courseDetail?.course_detail
                    ?.course_introduction_video_link !== undefined &&
                  courseDetail?.course_detail
                    ?.course_introduction_video_link !== null ? (
                    <div className="video-responsive tw-rounded-3xl tw-mt-8">
                      <iframe
                        className="tw-rounded-3xl"
                        src={`https://www.youtube.com/embed/${courseDetail?.course_detail?.course_introduction_video_link}?modestbranding=1&rel=0&iv_load_policy=3&fs=1&controls=1&disablekb=1`}
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      ></iframe>
                    </div>
                  ) : (
                    <img
                      src={NoVideo}
                      alt=""
                      className="my-4 tw-rounded-3xl"
                      style={{ width: "100%" }}
                    />
                  )}

                  {/* Course Description bar  */}
                  <div>
                    <ul
                      className={`tw-hidden md:tw-flex tw-space-x-4 tw-bg-white tw-px-4 tw-py-4 ${
                        fix
                          ? "md:tw-fixed md:tw-top-[88px] tw-mt-0 tw-z-10"
                          : "tw-mt-8 tw-rounded-2xl"
                      }`}
                    >
                      <li>
                        <button
                          className={`tw-py-3 tw-px-10 lg:tw-px-14 tw-font-semibold tw-leading-6 tw-border tw-rounded-3xl ${
                            activeTab === "description"
                              ? "tw-text-[#532D80] tw-border-[#532D80]"
                              : "tw-text-gray-600"
                          }`}
                          onClick={() => handleTabClick("description")}
                        >
                          {t("Description")}
                        </button>
                      </li>
                      <li>
                        <button
                          className={`tw-py-3 tw-px-10 lg:tw-px-14 tw-font-semibold tw-leading-6 tw-border tw-rounded-3xl ${
                            activeTab === "curriculum"
                              ? "tw-text-[#532D80] tw-border-[#532D80]"
                              : "tw-text-gray-600"
                          }`}
                          onClick={() => handleTabClick("curriculum")}
                        >
                          {t("Syllabus")}
                        </button>
                      </li>
                      <li>
                        <button
                          className={`tw-py-3 tw-px-10 lg:tw-px-14tw-font-semibold tw-leading-6 tw-border tw-rounded-3xl ${
                            activeTab === "my_review"
                              ? "tw-text-[#532D80] tw-border-[#532D80]"
                              : "tw-text-gray-600"
                          }`}
                          onClick={() => handleTabClick("my_review")}
                        >
                          {t("Review")}
                        </button>
                      </li>
                      <li>
                        <button
                          className={`tw-py-3 tw-px-10 lg:tw-px-14  tw-font-semibold tw-leading-6 tw-border tw-rounded-3xl ${
                            activeTab === "teachers"
                              ? "tw-text-[#532D80] tw-border-[#532D80]"
                              : "tw-text-gray-600"
                          }`}
                          onClick={() => handleTabClick("teachers")}
                        >
                          {t("Instructor")}
                        </button>
                      </li>
                      <li>
                        <button
                          className={`tw-py-3 tw-px-10 lg:tw-px-14  tw-font-semibold tw-leading-6 tw-border tw-rounded-3xl ${
                            activeTab === "faq"
                              ? "tw-text-[#532D80] tw-border-[#532D80]"
                              : "tw-text-gray-600"
                          }`}
                          onClick={() => handleTabClick("faq")}
                        >
                          {t("GeneralQuestions")}
                        </button>
                      </li>
                    </ul>

                    <div className="tw-mt-0">
                      <div
                        ref={descriptionRef}
                        data-section="description"
                        className="tw-mt-8"
                      >
                        <p className="tw-font-bold tw-text-lg tw-text-gray-700">
                          {t("Course Details")}
                        </p>
                        <CourseDescription
                          description={
                            courseDetail?.course_detail?.course_description
                          }
                        />
                      </div>
                      <div ref={timeScheduleRef}>
                        {courseDetail?.is_one_to_one_course &&
                          isSelectOneToOne && (
                            <OneToOneTimeSchedule courseNames={courseNames} />
                          )}
                      </div>
                      <div
                        ref={curriculumRef}
                        data-section="curriculum"
                        className="tw-mt-10"
                      >
                        <h2 className="tw-text-xl tw-font-bold">
                          {courseDetail?.course_detail?.course_name} -{" "}
                          {t("Syllabus")}
                        </h2>
                        <div className="tw-mt-4 tw-bg-white tw-shadow-lg tw-rounded-3xl tw-px-6 tw-py-3">
                          <div className="accordion" id="subject-accordion">
                            {displayedSyllabuses?.map(
                              (syllabus, syllabusIndex) => (
                                <div
                                  className="accordion-item"
                                  key={syllabus?.subject_id}
                                >
                                  <h3 className="accordion-header">
                                    <button
                                      className={`accordion-button ${
                                        syllabusIndex === 0 ? "" : "collapsed"
                                      }`}
                                      type="button"
                                      data-bs-toggle="collapse"
                                      data-bs-target={`#syllabus-content-${syllabus?.subject_id}`}
                                      aria-expanded={
                                        syllabusIndex === 0 ? "true" : "false"
                                      }
                                      aria-controls={`syllabus-content-${syllabus?.subject_id}`}
                                    >
                                      <span className="tw-bg-[#FFCE39] tw-px-4 tw-py-2 tw-rounded-lg tw-mr-6">
                                        {englishToBengaliNumberFormatWithZero(
                                          syllabusIndex + 1
                                        )}
                                      </span>
                                      {syllabus?.subject_name}
                                    </button>
                                  </h3>
                                  <div
                                    id={`syllabus-content-${syllabus?.subject_id}`}
                                    className={`accordion-collapse collapse ${
                                      syllabusIndex === 0 ? "show" : ""
                                    }`}
                                    aria-labelledby={`heading-${syllabus?.subject_id}`}
                                    data-bs-parent="#subject-accordion"
                                  >
                                    <div className="accordion-body">
                                      {syllabus?.lessons?.length === 0 ? (
                                        <p className="tw-text-center tw-text-xl">
                                          এখনো কোনো বিষয়বস্তু যুক্ত করা হয়নি।
                                        </p>
                                      ) : (
                                        syllabus?.lessons?.map(
                                          (lesson, lessonIndex) => (
                                            <ClassSchedule
                                              key={lesson?.lesson_id}
                                              lesson={lesson}
                                              LessonSerial={lessonIndex}
                                            />
                                          )
                                        )
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )
                            )}
                            {/* Show the "More" button if there are more than 4 syllabuses */}
                            {courseDetail?.syllabuses.length > 4 &&
                              !showAllSyllabuses && (
                                <div className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-mt-4">
                                  <button
                                    className="tw-flex tw-text-[#532D80] tw-text-lg tw-font-bold"
                                    onClick={handleToggleSyllabuses}
                                  >
                                    <span className="tw-mr-2">
                                      {t("See More")}
                                    </span>
                                    <ArrowDownIcon className="tw-w-5 tw-mt-1" />
                                  </button>
                                </div>
                              )}
                            {/* Show the "Less" button when all syllabuses are displayed */}
                            {/*{showAllSyllabuses && (*/}
                            {/*  <button*/}
                            {/*    className="tw-mt-4 tw-p-2 tw-bg-gray-500 tw-text-white tw-rounded"*/}
                            {/*    onClick={handleToggleSyllabuses}*/}
                            {/*  >*/}
                            {/*    Less*/}
                            {/*  </button>*/}
                            {/*)}*/}
                          </div>
                        </div>
                      </div>

                      {/* Review  */}
                      <div
                        ref={reviewRef}
                        data-section="my_review"
                        className="tw-mt-8"
                      >
                        <CourseReview
                          course_id={course_id}
                          isCoursePurchase={
                            courseDetail?.course_detail?.already_purchased
                          }
                        />
                      </div>

                      {/* Course Instructor */}
                      <div
                        ref={teachersRef}
                        data-section="teachers"
                        className="tw-mt-8"
                      >
                        <h2 className="tw-text-xl tw-font-bold tw-text-gray-700">
                          {t("Course Instructor")}
                        </h2>
                        <div className="tw-mt-4 tw-grid tw-grid-cols-2 tw-bg-white tw-rounded-3xl">
                          {displayedTeachers.map((teacher) => (
                            <div
                              key={teacher.id}
                              className="tw-border-b tw-p-4"
                            >
                              <CourseTeacher
                                teacher={teacher}
                                teacherImagePath={teacherImagePath}
                              />
                            </div>
                          ))}
                          {/* Show the "More" button if there are more than 6 teachers */}
                          {/*/!* Show the "Less" button when all teachers are displayed *!/*/}
                          {/*{showAllTeacher && (*/}
                          {/*    <button*/}
                          {/*        className="tw-mt-4 tw-p-2 tw-bg-gray-500 tw-text-white tw-rounded"*/}
                          {/*        onClick={handleToggle}*/}
                          {/*    >*/}
                          {/*        Less*/}
                          {/*    </button>*/}
                          {/*)}*/}
                        </div>

                        {teachers.length > 6 && !showAllTeacher && (
                          <div className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-mt-4">
                            <button
                              className="tw-flex tw-text-[#532D80] tw-text-lg tw-font-bold"
                              onClick={handleToggle}
                            >
                              <span className="tw-mr-2">{t("See More")}</span>{" "}
                              <ArrowDownIcon className="tw-w-5 tw-mt-1" />
                            </button>
                          </div>
                        )}
                      </div>
                      <div ref={faqRef} data-section="faq" className="tw-mt-10">
                        <h2 className="tw-text-xl tw-font-bold">{t("FAQ")}</h2>
                        <div className="tw-mt-4 tw-bg-white tw-rounded-3xl">
                          {courseDetail?.course_faqs?.map((course_faq) => (
                            <Faq
                              key={course_faq.course_faq_id}
                              course_faq={course_faq}
                            />
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 ">
                  <CourseShortInformation
                    id={course_id}
                    isCoursePurchase={
                      courseDetail?.course_detail?.already_purchased
                    }
                    courseDetail={courseDetail?.course_detail}
                    courseSubjectCount={courseDetail?.course_subject_count}
                    course_features={courseDetail?.course_features}
                    course_teachers={courseDetail?.course_teachers}
                    teacher_profile_path={
                      courseDetail?.teacher_profile_picture_path
                    }
                    affiliate_id={affiliate_id}
                    open={setIsOpen}
                    isOneToOneCourse={courseDetail?.is_one_to_one_course}
                    handleTimeScheduleRef={handleTimeScheduleRef}
                    setIsSelectOneToOne={setIsSelectOneToOne}
                  ></CourseShortInformation>
                </div>
              </div>
            </div>
          </div>

          <SlideOver
            isOpen={isOpen}
            onClose={handleOnClose}
            title="মজারু কোর্স বাকেট"
          >
            <div className="tw-flex tw-flex-col">
              <div className="tw-flex">
                <img
                  className="tw-w-24 tw-mr-6 tw-rounded-lg"
                  src={imagePath + imageLink}
                  alt="iamge"
                />
                <div className="">
                  <p>{courseDetail?.course_detail?.course_name}</p>
                  <div className="tw-flex tw-mt-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM9.5547 7.16795C9.24784 6.96338 8.8533 6.94431 8.52814 7.11833C8.20298 7.29235 8 7.63121 8 8V12C8 12.3688 8.20298 12.7077 8.52814 12.8817C8.8533 13.0557 9.24784 13.0366 9.5547 12.8321L12.5547 10.8321C12.8329 10.6466 13 10.3344 13 10C13 9.66565 12.8329 9.35342 12.5547 9.16795L9.5547 7.16795Z"
                        fill="#9CA3AF"
                      />
                    </svg>
                    <p className="tw-ml-2">
                      {courseDetail?.course_detail?.course_total_live_class}{" "}
                      class
                    </p>
                  </div>
                  <div className="tw-flex tw-mt-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M2 11C2 10.4477 2.44772 10 3 10H5C5.55228 10 6 10.4477 6 11V16C6 16.5523 5.55228 17 5 17H3C2.44772 17 2 16.5523 2 16V11Z"
                        fill="#9CA3AF"
                      />
                      <path
                        d="M8 7C8 6.44772 8.44772 6 9 6H11C11.5523 6 12 6.44772 12 7V16C12 16.5523 11.5523 17 11 17H9C8.44772 17 8 16.5523 8 16V7Z"
                        fill="#9CA3AF"
                      />
                      <path
                        d="M14 4C14 3.44772 14.4477 3 15 3H17C17.5523 3 18 3.44772 18 4V16C18 16.5523 17.5523 17 17 17H15C14.4477 17 14 16.5523 14 16V4Z"
                        fill="#9CA3AF"
                      />
                    </svg>
                    <p className="tw-ml-2">Level 4</p>
                  </div>
                </div>
              </div>

              <div className="tw-relative tw-mt-5">
                <div
                  className="tw-absolute tw-inset-0 tw-flex tw-items-center"
                  aria-hidden="true"
                >
                  <div className="tw-w-full tw-border-t tw-border-gray-300" />
                </div>
              </div>

              <div className="tw-mt-2">
                <label
                  for="location"
                  className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900"
                >
                  <div className="tw-flex tw-items-center">
                    <p className="tw-mr-2">ব্যাচ সিলেক্ট করো</p>
                    <svg
                      className=""
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M18 10.01C18 14.4283 14.4183 18.01 10 18.01C5.58172 18.01 2 14.4283 2 10.01C2 5.59173 5.58172 2.01001 10 2.01001C14.4183 2.01001 18 5.59173 18 10.01ZM11 6.01001C11 6.56229 10.5523 7.01001 10 7.01001C9.44772 7.01001 9 6.56229 9 6.01001C9 5.45773 9.44772 5.01001 10 5.01001C10.5523 5.01001 11 5.45773 11 6.01001ZM9 9.01001C8.44772 9.01001 8 9.45773 8 10.01C8 10.5623 8.44772 11.01 9 11.01V14.01C9 14.5623 9.44772 15.01 10 15.01H11C11.5523 15.01 12 14.5623 12 14.01C12 13.4577 11.5523 13.01 11 13.01V10.01C11 9.45773 10.5523 9.01001 10 9.01001H9Z"
                        fill="#9CA3AF"
                      />
                    </svg>
                  </div>
                </label>
                <select
                  id=""
                  name=""
                  className="tw-mt-2 tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-pl-3 tw-pr-10 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 tw-focus:ring-2 tw-focus:ring-indigo-600 tw-sm:text-sm tw-sm:leading-6"
                  defaultValue={batchName}
                  onChange={handleBatch}
                >
                  {courseBatch &&
                    courseBatch.map((batch, index) => (
                      <option key={batch.id}>{batch?.batch_name}</option>
                    ))}
                </select>
              </div>

              <div className="tw-relative tw-mt-6">
                <div
                  className="tw-absolute tw-inset-0 tw-flex tw-items-center"
                  aria-hidden="true"
                >
                  <div className="tw-w-full tw-border-t tw-border-gray-300" />
                </div>
              </div>

              <div className="tw-mt-6">
                <PaymentSegment
                  coursePayment={coursePayment}
                  handleSelectedPaymentChange={handleSelectedPaymentChange}
                  setSelectedPayment={setSelectedPayment}
                  selectedPayment={selectedPayment}
                />
              </div>
              <Link
                to="/course-price"
                className="tw-mt-4 tw-bg-[#6F39B7] tw-text-white tw-flex tw-justify-center tw-py-3 tw-rounded-lg"
                onClick={handleClick}
              >
                <p className="tw-text-white">কোর্সে ভর্তি কনফার্ম করো</p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9.29324 16.707C9.68424 17.098 10.3162 17.098 10.7072 16.707L14.7072 12.707C15.0982 12.316 15.0982 11.684 14.7072 11.293L10.7072 7.29299C10.5122 7.09799 10.2562 6.99999 10.0002 6.99999C9.74424 6.99999 9.48824 7.09799 9.29324 7.29299C8.90224 7.68399 8.90224 8.31599 9.29324 8.70699L12.5862 12L9.29324 15.293C8.90224 15.684 8.90224 16.316 9.29324 16.707Z"
                    fill="white"
                  />
                </svg>
              </Link>
            </div>
          </SlideOver>
        </OverlayProvider>
      </>
    </CourseDetailContext.Provider>
  );
};

export default CourseDetails;

//   useEffect(() => {
//     const sections = [
//       { ref: descriptionRef, name: "description" },
//       { ref: curriculumRef, name: "curriculum" },
//       { ref: teachersRef, name: "teachers" },
//       { ref: faqRef, name: "faq" },
//       { ref: reviewRef, name: "my_review" },
//     ];

//     const observer = new IntersectionObserver(
//       (entries) => {
//         entries.forEach((entry) => {
//           if (entry.isIntersecting) {
//             const section = sections.find(
//               (sec) => sec.ref.current === entry.target
//             );
//             if (section) {
//               setActiveTab(section.name);
//             }
//           }
//         });
//       },
//       { threshold: 0.7 }
//     );

//     sections.forEach(({ ref }) => {
//       if (ref.current) {
//         observer.observe(ref.current);
//       }
//     });

//     return () => {
//       sections.forEach(({ ref }) => {
//         if (ref.current) {
//           observer.unobserve(ref.current);
//         }
//       });
//     };
//   }, []);

// fixed course navbar at the top function
