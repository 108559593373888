import { useState } from "react";
import { RadioGroup } from "@headlessui/react";
import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { englishToBengaliNumberFormatWithZero } from "../../../../../utilities/utilities";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const admission_price = localStorage.getItem("admission_fee") || 6500;

const PaymentSegment = ({
  coursePayment,
  setSelectedPayment,
  selectedPayment,
  handleSelectedPaymentChange,
}) => {
  return (
    <div>
      {/* Fixed Div Start  */}
      <div className="tw-w-full tw-max-w-lg tw-my-4">
        <div className="tw-relative tw-rounded-lg tw-border-2 tw-border-[#6F39B7] tw-bg-[#dec8fc]">
          <div className="tw-absolute tw-right-4 tw-top-4">
            <CheckCircleIcon aria-hidden="true" />
          </div>
          <div className="tw-space-y-1 tw-p-3">
            <p className="">Admission fee</p>
            <div className="tw-flex tw-justify-between tw-items-center">
              <p className="tw-text-gray-500" lang="bn">
                মেয়াদ ০ দিন
              </p>
              <p className="tw-text-md tw-font-semibold" lang="bn">
                {englishToBengaliNumberFormatWithZero(admission_price)}
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* Fixed Div End  */}
      <RadioGroup
        value={selectedPayment}
        onChange={handleSelectedPaymentChange}
      >
        <RadioGroup.Label className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900">
          ভর্তির মেয়াদ সিলেক্ট করো
        </RadioGroup.Label>

        <div className="tw-mt-4 tw-grid tw-grid-cols-1 tw-gap-y-6 tw-sm:grid-cols-3 tw-sm:gap-x-4">
          {coursePayment.map((course) => (
            <RadioGroup.Option
              key={course.id}
              value={course}
              className={({ active }) =>
                classNames(
                  active
                    ? "tw-border-[#6F39B7] tw-ring-2 tw-ring-[#6F39B7]"
                    : "tw-border-gray-300",
                  "tw-relative tw-flex tw-cursor-pointer tw-rounded-lg tw-border tw-bg-white tw-p-4 tw-shadow-sm tw-focus:outline-none"
                )
              }
            >
              {({ checked, active }) => (
                <>
                  <span className="tw-flex tw-flex-1">
                    <div className="tw-w-full tw-flex tw-justify-between tw-items-center">
                      <div className="">
                        <RadioGroup.Label
                          as="span"
                          className="font-medium text-gray-900"
                        >
                          {course.payment_segment_level}
                        </RadioGroup.Label>
                        <RadioGroup.Description
                          as="span"
                          className="tw-mt-1 tw-flex tw-items-center tw-text-sm tw-text-gray-500"
                        >
                          {course.payment_segment_duration_in_days > 0 && (
                            <span className="tw-text-sm">
                              মেয়াদ
                              <span className="tw-mx-1">
                                {englishToBengaliNumberFormatWithZero(
                                  course.payment_segment_duration_in_days
                                )}
                              </span>
                              দিন
                            </span>
                          )}
                        </RadioGroup.Description>
                      </div>
                      <RadioGroup.Description
                        as="span"
                        className="tw-mt-6 tw-text-md tw-font-bold tw-text-gray-900"
                      >
                        {englishToBengaliNumberFormatWithZero(
                          course.course_final_price
                        )}
                      </RadioGroup.Description>
                    </div>
                  </span>
                  <CheckCircleIcon
                    className={classNames(
                      !checked ? "tw-invisible" : "",
                      "tw-h-5 tw-w-5 tw-text-[#6F39B7]"
                    )}
                    aria-hidden="true"
                  />
                  <span
                    className={classNames(
                      active ? "tw-border" : "tw-border-2",
                      checked ? "tw-border-[#6F39B7]" : "tw-border-transparent",
                      "tw-pointer-events-none tw-absolute tw--inset-px tw-rounded-lg"
                    )}
                    aria-hidden="true"
                  />
                </>
              )}
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
    </div>
  );
};

export default PaymentSegment;
